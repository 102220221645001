import { Typography } from "@mui/material";
import React from "react";
import { formatPackageCode } from "../../Utils/format";
import { PackagePurchaseWrapper } from "./style";

interface IProps {
  numOfSessions: string;
  customerEmail: string;
  packageCode: string;
}
const PackagePurchaseDetails: React.FC<IProps> = ({
  numOfSessions,
  customerEmail,
  packageCode,
}) => {
  return (
    <PackagePurchaseWrapper>
      <Typography className="title"> Package Purchase </Typography>
      <Typography className="package-description">
        {`Payment received and your purchase of ${numOfSessions} Sessions is confirmed. We’ve
        sent all the details to your email at:`}
      </Typography>
      <Typography className="customer-email">{customerEmail}</Typography>
      <Typography className="package-code-label"> Package Code </Typography>
      <Typography className="package-code-value">{packageCode}</Typography>
      <Typography className="save-reminder">
        Please save this code to use it on the checkout page of your next
        booking.
      </Typography>
    </PackagePurchaseWrapper>
  );
};

export default PackagePurchaseDetails;
