export const BASE_URL =
  "https://us-central1-skmd-test-ef758.cloudfunctions.net/booking";

// export const BASE_URL =
//   "https://us-central1-skmd-299311.cloudfunctions.net/booking";

export const GOOGLE_ANALYTICS_ID_PROD = "G-3C57FNQETF";
export const GOOGLE_ANALYTICS_ID_DEV = "G-PYM7SBGB3J";

export const RequestStatus = {
  FailedCancellation: "FailedCancellation",
  SuccessfulCancellation: "SuccessfulCancellation",
  UnSuccessfulPayment: "UnSuccessfulPayment",
  FailedPackageCancellation: "FailedPackageCancellation",
  SuccessfulPackageCancellation: "SuccessfulPackageCancellation",
};

export const VoucherErrors = {
  DURATION: "DURATION",
  INVALID_VOUCHER: "INVALID_VOUCHER",
};

export const TimeUnits = {
  SESSION: "session",
  HOUR: "hour",
};

export const SESSION_DURATION = 15;

export const DEFAULT_MAX_QUANTITY = 6;

export const STATUS_MESSAGES: Map<string, string> = new Map([
  [
    RequestStatus.FailedCancellation,
    "Booking cannot be cancelled because the booking start time is after the cancellation policy limit.",
  ],
  [
    RequestStatus.SuccessfulCancellation,
    "Your booking is cancelled and a refund to your original mode of payment is being processed. Refunds can take 7-14 working days to reflect in your account .",
  ],
  [
    RequestStatus.UnSuccessfulPayment,
    "Unfortunately we were not able to process your payment. Please try again.",
  ],
  [
    RequestStatus.FailedPackageCancellation,
    "Package cannot be cancelled once used.",
  ],
]);


export const WEEKEND_DAYS = [0,5,6]