import { Company } from "../hooks/useCompany";
import { SESSION_DURATION } from "./constants";
import { convertMinutesToHours, getFormattedTime } from "./format";

export const extractBookingDetails = (searchParams: URLSearchParams) => {
  const companyName = searchParams.get("companyName");
  const locationName = searchParams.get("location");
  const serviceName = searchParams.get("serviceName");
  const price = searchParams.get("price");
  const bookingId = searchParams.get("bookingId");
  console.log("[DEBUG]", searchParams.get("date"));
  const date = getFormattedTime(
    searchParams.get("date")!,
    "dddd, MMMM DD",
    true
  );
  console.log("startTime", searchParams.get("startTime"));
  const startTime = searchParams.get("startTime")!;
  const duration = convertMinutesToHours(
    Number(searchParams.get("durationInMinutes"))
  );
  const quantity = searchParams.get("quantity");

  return {
    companyName,
    locationName,
    serviceName,
    date,
    startTime,
    duration,
    price,
    bookingId,
    quantity,
  };
};

export const extractPackageDetails = (searchParams: URLSearchParams) => {
  const numOfSessions = `${
    Number(searchParams.get("duration")) / SESSION_DURATION
  }`;
  const customerEmail = searchParams.get("customerEmail");
  const packageCode = searchParams.get("voucherCode");
  return {
    numOfSessions,
    customerEmail,
    packageCode,
  };
};

export const extractPackageCancellationDetails = (
  searchParams: URLSearchParams
) => {
  const numOfSessions = searchParams.get("numberOfSessions")!;
  const price = searchParams.get("price")!;
  const packageCode = searchParams.get("voucherCode")!;
  const companyId = searchParams.get("companyId")!;
  const companyName = searchParams.get("companyName")!;
  return {
    numOfSessions,
    price,
    packageCode,
    companyId,
    companyName,
  };
};

export const getResourceDisclaimer = (
  company: Company,
  selectedResource: string
) => {
  const resource = company?.resourcesInformation?.find(
    (resource) => resource.resourceId === selectedResource
  );
  return resource?.disclaimer;
};

export const getTimeUnit = (company: Company, selectedResource: string) => {
  const resource = company?.resourcesInformation?.find(
    (resource) => resource.resourceId === selectedResource
  );
  return resource?.timeUnit || company?.timeUnit || "hour";
};

export const getPrice = (price: string) => {
  return Number(price.split(" ")[0]);
};
