import { Typography } from "@mui/material";
import React from "react";
import { AvailableStartingTimesResource, AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";
import DurationsList from "./DurationsList";
import { DurationFilterWrapper } from "./style";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  selectedDay: string;
  bookingSlots: Map<string, AvailableStartingTimesSlot[]>;

}

const DurationFilter: React.FC<IProps> = ({
  availableStartingTimes,
  selectedDay,
  bookingSlots
}) => {
   
  return (
    <DurationFilterWrapper>
      <Typography className="duration-label">Filter By Duration</Typography>
      <DurationsList
        availableStartingTimes={availableStartingTimes}
        selectedDay={selectedDay}
        bookingSlots={bookingSlots}
      />
    </DurationFilterWrapper>
  );
};

export default DurationFilter;
