import Axios from "axios";
import { useState } from "react";
import { BASE_URL } from "../Utils/constants";

interface CancelBookingInput {
  companyId: string;
  packageCode: string;
  onSuccessfulCancellation: () => void;
  onFailedCancellation: () => void;
}

const useCancelPackage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelPackage = async ({
    companyId,
    packageCode,
    onSuccessfulCancellation,
    onFailedCancellation,
  }: CancelBookingInput) => {
    setIsLoading(true);
    try {
      const { data: response } = await Axios.post(`${BASE_URL}/cancelVoucher`, {
        companyId,
        voucherCode: packageCode,
      });
      if (response.error === 0) {
        setIsLoading(false);
        onSuccessfulCancellation();
      } else {
        setIsLoading(false);
        onFailedCancellation();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      onFailedCancellation();
    }
  };

  return { isLoading, cancelPackage };
};

export default useCancelPackage;
