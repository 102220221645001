import { Typography } from "@mui/material";
import React from "react";
import {
  AvailableStartingTimesSlot,
  AvailableStartingTimesResource,
  BookingSlot,
} from "../../../hooks/useAvailableStartingTimes";
import SlotsList from "./SlotsList";
import { SlotsSectionWrapper } from "./style";

interface IProps {
  label: string;
  slots: AvailableStartingTimesSlot[];
  availableStartingTimes: AvailableStartingTimesResource;
}
const SlotsSection: React.FC<IProps> = ({
  label,
  slots,
  availableStartingTimes,
}) => {
  return (
    <SlotsSectionWrapper>
      <Typography className="label">{label}</Typography>
      <SlotsList
        slots={slots}
        availableStartingTimes={availableStartingTimes}
      />
    </SlotsSectionWrapper>
  );
};

export default SlotsSection;
