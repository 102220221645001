import Axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../Utils/constants";

interface AvailableStartingTimesInput {
  companyId?: string | null;
  branchId?: string | null;
  serviceId?: string | null;
  selectedDay?: string | null;
  selectedResource?: string | null;
  isAllResourcesOptionEnabled: boolean;
}

export interface AvailableStartingTimesSlot {
  fullDate: string;
  date: string;
  time: string;
  available: number;
  isPeak: boolean;
  price: number;
}

export interface BookingSlot extends AvailableStartingTimesSlot {
  durationIndices: number[];
}

export interface AvailableStartingTimesDuration {
  id: string;
  serviceId: string;
  isWeekend: boolean;
  isWeekday: boolean;
  currency: string;
  price: number;
  durationTime: number;
  slots: AvailableStartingTimesSlot[];
  timeMultipleOf: number;
  peakPrice: number;
  offPeakPrice: number;
}

export interface AvailableStartingTimesResource {
  active?: string;
  name?: string;
  id: string;
  branchId?: string;
  companyId?: string;
  duration: AvailableStartingTimesDuration[];
}

interface AvailableStartingTimesService {
  branchId: string;
  description: string;
  duration: AvailableStartingTimesDuration[];
}
interface AvailableStartingTimesOutput {
  id: string;
  address: string;
  timezone: string;
  service: AvailableStartingTimesService;
}

const useAvailableStartingTimes = ({
  companyId,
  branchId,
  serviceId,
  selectedDay,
  selectedResource,
  isAllResourcesOptionEnabled,
}: AvailableStartingTimesInput) => {
  const [availableStartingTimes, setAvailableStartingTimes] =
    useState<AvailableStartingTimesResource>();
  const [bookingSlots, setBookingSlots] = useState< Map<string, AvailableStartingTimesSlot[]>>(new Map());
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const abortController = new AbortController();

    const fetchAvailableStartingTimes = async () => {
      setLoading(true);
      try {
        const { data: response } = await Axios.post(
          `${BASE_URL}/getAvailableStartingTimes`,
          {
            companyId,
            branchId,
            serviceId,
            date: selectedDay,
            resourceId: isAllResourcesOptionEnabled
              ? undefined
              : selectedResource,
          },
          {
            signal: abortController.signal,
          }
        );

        const output: AvailableStartingTimesOutput = response.data;
       
        const availableStartingTimesResource =
          getResourceAvailableStartingTimes(output, selectedResource!);

         
        setAvailableStartingTimes(availableStartingTimesResource);
        setBookingSlots(
          getAvailableBookingSlots(availableStartingTimesResource)
        );
      } catch (e) {
        setAvailableStartingTimes(undefined);
        setBookingSlots(new Map());
        console.log(e);
      }
      setLoading(false);
    };

    if (companyId && branchId && serviceId && selectedDay) {
      fetchAvailableStartingTimes();
    }

    return () => {
      abortController.abort();
    };
  }, [selectedDay, selectedResource, isAllResourcesOptionEnabled, branchId]);
  return { availableStartingTimes, bookingSlots, loading };
};

const getResourceAvailableStartingTimes = (
  availableStartingTimesOutput: AvailableStartingTimesOutput,
  selectedResource: string
): AvailableStartingTimesResource => {
  // sort by duration
  availableStartingTimesOutput.service.duration.sort((a, b) => {
    return a.durationTime - b.durationTime;
  });

  const resourceAvailableStartingTimes: AvailableStartingTimesResource = {
    duration: availableStartingTimesOutput.service.duration,
    id: selectedResource,
  };

  return resourceAvailableStartingTimes;
};

const getAvailableBookingSlots = (
  availableStartingTimesResource: AvailableStartingTimesResource
) => {
  const bookingSlots: Map<string, AvailableStartingTimesSlot[]> = new Map();
  availableStartingTimesResource.duration.forEach((duration) => {
    const availableSlots: AvailableStartingTimesSlot[] = [];
    duration.slots.forEach((slot) => {
      if (slot.available <= 0) return;
      availableSlots.push(slot);
    });
    bookingSlots.set(duration.id,availableSlots);
  });

 return bookingSlots;
};

export default useAvailableStartingTimes;
