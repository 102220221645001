import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  .back-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .back-arrow-icon {
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .header-label {
      font-size: 18px;
      font-style: normal;
      font-family: airbnb-cereal-app;
      font-weight: normal;
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }
`;
