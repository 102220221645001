import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { down } from "styled-breakpoints";

export const SlotSelectorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .no-slots-label {
    text-align: center;
  }
`;

export const DurationFilterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .duration-label {
    color: #33334f;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-family: airbnb-cereal-app;
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 24px;
  }
`;

export const DurationListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // width: 100%;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 40%);
  border-radius: 16px;
  background-color: #fff;
  padding: 10px 20px;
  flex-wrap: wrap;
`;

interface DurationChipWrapperProps {
  isSelected: boolean;
}
export const DurationChipWrapper = styled(Box)<DurationChipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  padding: 5px 10px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #ffffff;

  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-family: airbnb-cereal-app;
  font-weight: normal;
  line-height: 25px;
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#808080")};
  background-color: ${({ isSelected }) => (isSelected ? "#5182FF" : "#fff")};
  box-shadow: 0px 10px 40px #d4d9e8;
  border-radius: 12px;
  width: 90px;
  ${down("sm")} {
    padding: 5px 0px;
  }

  .duration {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-family: airbnb-cereal-app;
    font-weight: normal;
    line-height: 25px;
  }

  .price {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: airbnb-cereal-app-light;
    font-weight: normal;
    line-height: 25px;
  }
`;

export const AvailableStartingTimesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .availability-Label {
    color: #33334f;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-family: airbnb-cereal-app;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .no-slots-label {
    color: #000000;
    padding: 30px 20px;
    font-size: 18px;
    text-align: center;
    font-family: airbnb-cereal-app;
    font-weight: normal;
    line-height: 140%;
  }
`;

export const SlotsSectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: calc(100% - 20px);
  background: #ffffff;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 20px;

  .label {
    color: #33334f;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-family: airbnb-cereal-app;
    font-weight: normal;
  }
`;

export const SlotsListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

interface SlotChipWrapperProps {
  isSelected: boolean;
}
export const SlotChipWrapper = styled(Box)<SlotChipWrapperProps>`
  &:hover {
    cursor: pointer;
  }
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  color: #000000;
  font-size: 12px;
  text-align: center;
  font-family: airbnb-cereal-app-light;
  font-weight: normal;
  line-height: 140%;
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#808080")};
  background-color: ${({ isSelected }) => (isSelected ? "#5182FF" : "#fff")};
`;

export const PopoverWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  padding: 10px;

  .popover-item {
    margin: 10px;
    color: #000000;
    font-size: 14px;
    text-align: center;
    font-family: airbnb-cereal-app-light;

    line-height: 140%;
    &:hover {
      cursor: pointer;
      background-color: #5182ff;
      color: #fff;
    }
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 50%);
  }
`;
