import React, { ReactNode } from "react";
import {
  AvailableStartingTimesDuration,
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
} from "../hooks/useAvailableStartingTimes";
import { Branch, Company, Resource, Service } from "../hooks/useCompany";

export interface CheckoutContextProps {
  company?: Company;
  setCompany: (company: Company) => void;
  selectedSlot?: AvailableStartingTimesSlot;
  setSelectedSlot: (slot: AvailableStartingTimesSlot | undefined) => void;
  selectedDuration?: AvailableStartingTimesDuration;
  setSelectedDuration: (duration: AvailableStartingTimesDuration) => void;
  selectedLocation: string;
  setSelectedLocation: (location: string) => void;
  selectedService: string;
  setSelectedService: (service: string) => void;
  shouldShowServiceDropdown: boolean;
  setShouldShowServiceDropdown: (value: boolean) => void;
  selectedResource: string;
  setSelectedResource: (resource: string) => void;
  isAllResourcesOptionEnabled: boolean;
  setIsAllResourcesOptionEnabled: (value: boolean) => void;
  availableStartingTimesResource?: AvailableStartingTimesResource;
  setAvailableStartingTimesResource: (
    resource: AvailableStartingTimesResource
  ) => void;
  resourcesMap?: Map<string, Resource>;
  setResourcesMap: (resourcesMap: Map<string, Resource>) => void;
  branchesMap?: Map<string, Branch>;
  setBranchesMap: (branchesMap: Map<string, Branch>) => void;
  servicesMap?: Map<string, Service>;
  setServicesMap: (branchesMap: Map<string, Service>) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
}

export const CheckoutContext = React.createContext<CheckoutContextProps | null>(
  null
);

interface Props {
  children?: ReactNode;
}
const CheckoutContextProvider = ({ children }: Props) => {
  const [company, setCompany] = React.useState<Company>();
  const [selectedSlot, setSelectedSlot] = React.useState<
    AvailableStartingTimesSlot | undefined
  >();
  const [selectedDuration, setSelectedDuration] =
    React.useState<AvailableStartingTimesDuration>();
  const [selectedLocation, setSelectedLocation] = React.useState<string>("");
  const [selectedService, setSelectedService] = React.useState<string>("");
  const [shouldShowServiceDropdown, setShouldShowServiceDropdown] =
    React.useState<boolean>(false);
  const [selectedResource, setSelectedResource] = React.useState<string>("");
  const [isAllResourcesOptionEnabled, setIsAllResourcesOptionEnabled] =
    React.useState<boolean>(false);
  const [availableStartingTimesResource, setAvailableStartingTimesResource] =
    React.useState<AvailableStartingTimesResource>();
  const [branchesMap, setBranchesMap] = React.useState<Map<string, Branch>>(
    new Map()
  );
  const [servicesMap, setServicesMap] = React.useState<Map<string, Service>>(
    new Map()
  );
  const [resourcesMap, setResourcesMap] = React.useState<Map<string, Resource>>(
    new Map()
  );

  const [quantity, setQuantity] = React.useState<number>(1);

  return (
    <CheckoutContext.Provider
      value={{
        company,
        setCompany,
        selectedSlot,
        setSelectedSlot,
        selectedDuration,
        setSelectedDuration,
        selectedLocation,
        setSelectedLocation,
        selectedService,
        setSelectedService,
        shouldShowServiceDropdown,
        setShouldShowServiceDropdown,
        selectedResource,
        setSelectedResource,
        isAllResourcesOptionEnabled,
        setIsAllResourcesOptionEnabled,
        availableStartingTimesResource,
        setAvailableStartingTimesResource,
        resourcesMap,
        setResourcesMap,
        branchesMap,
        setBranchesMap,
        servicesMap,
        setServicesMap,
        quantity,
        setQuantity,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContextProvider;
