import React from "react";
import { AvailableStartingTimesResource, AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";
import DurationChip from "./DurationChip";
import { DurationListWrapper } from "./style";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  selectedDay: string;
  bookingSlots: Map<string, AvailableStartingTimesSlot[]>;

}
const DurationsList: React.FC<IProps> = ({
  availableStartingTimes,
  selectedDay,
  bookingSlots
}) => {
  return (
    <DurationListWrapper>
      {availableStartingTimes &&
        availableStartingTimes.duration.map((duration, index) => (
          <DurationChip
            key={index}
            duration={duration}
            index={index}
            selectedDay={selectedDay}
            bookingSlots={bookingSlots}
          />
        ))}
    </DurationListWrapper>
  );
};

export default DurationsList;
