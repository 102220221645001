import { Box, Popover, Typography } from "@mui/material";

import React, { useContext, useEffect } from "react";
import {
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
  BookingSlot,
} from "../../../hooks/useAvailableStartingTimes";
import { PopoverWrapper, SlotChipWrapper } from "./style";
import { useNavigate } from "react-router-dom";
import {
  CheckoutContextProps,
  CheckoutContext,
} from "../../../context/CheckoutContext";
import {
  convertMinutesToHours,
  convertMinutesToSessions,
  formatDurationTime,
  getFormattedTime,
} from "../../../Utils/format";
import { TimeUnits } from "../../../Utils/constants";

interface IProps {
  slot: AvailableStartingTimesSlot;
  availableStartingTimes: AvailableStartingTimesResource;
}
const SlotChip: React.FC<IProps> = ({
  slot,
  availableStartingTimes,
}) => {
  const navigate = useNavigate();
  const {
    company,
    selectedSlot,
    setSelectedSlot,
    selectedDuration,
    selectedLocation,
    selectedResource,
    quantity,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    setSelectedSlot(undefined);
  }, [selectedLocation, selectedResource, setSelectedSlot]);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent) => {
    setSelectedSlot(slot);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverItemClick = () => {
    navigate(`/checkout`);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isSelected = selectedSlot?.time === slot.time;  

  

  return (
    <>
      <SlotChipWrapper
        onClick={handleClick}
        aria-describedby={id}
        isSelected={isSelected}
      >
        <Typography>
          {getFormattedTime(slot.fullDate, "hh:mm A", false, "Asia/Dubai")}
        </Typography>
      </SlotChipWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverWrapper>
         
             <Box  
             onClick={() => handlePopoverItemClick()}
             className={"popover-item"}
             >
             
              <Typography
             
              >
                {formatDurationTime(
                  selectedDuration!.durationTime,
                  company!,
                  selectedResource
                )}
              </Typography>

             </Box>
          
        </PopoverWrapper>
      </Popover>
    </>
  );
};

export default SlotChip;
