import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { down } from "styled-breakpoints";

export const QuantitySelectorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;

  .select {
    width: 10%;
    ${down("md")} {
      width: 50%;
    }
  }

  .label {
    color: #33334f;
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-family: airbnb-cereal-app;
    font-weight: 500;
  }
`;
