import Axios from "axios";
import { useState } from "react";
import { BASE_URL } from "../Utils/constants";

interface PurchasePackageInput {
  companyId: string;
  price: number;
  customerInformation: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

const usePackagePurchase = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseError, setPurchaseError] = useState<string | null>(null);
  const purchasePackage = async ({
    companyId,
    price,
    customerInformation,
  }: PurchasePackageInput) => {
    setIsLoading(true);
    setPurchaseError(null);
    try {
      const { data: response } = await Axios.post(`${BASE_URL}/voucher`, {
        companyId,
        price,
        ...customerInformation,
        type: "duration",
      });
      if (response.error === 0) {
        window.location.replace(response.data.paymentUrl);
      } else {
        setPurchaseError(response.message);
      }
    } catch (e: any) {
      setPurchaseError(e.response.data.message);
    }
    setIsLoading(false);
  };

  return { purchasePackage, isLoading, purchaseError };
};

export default usePackagePurchase;
