import { Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
  BookingSlot,
} from "../../../hooks/useAvailableStartingTimes";
import { getResourceDisclaimer } from "../../../Utils/extract";
import ContentLoader from "../../ContentLoader";
import AvailableStartingTimes from "./AvailableStartingTimes";
import DurationFilter from "./DurationFilter";
import { SlotSelectorWrapper } from "./style";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  isLoadingStartingTimes: boolean;
  bookingSlots: Map<string, AvailableStartingTimesSlot[]>;
  selectedDay:string;
}

const TimeSelector: React.FC<IProps> = ({
  isLoadingStartingTimes,
  availableStartingTimes,
  bookingSlots,
  selectedDay
}) => {
  const { company, selectedResource , selectedDuration } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;
  console.log("availableStartingTimes8",availableStartingTimes)

  const durationSlots = bookingSlots.get(selectedDuration?.id || "")
  return (
    <SlotSelectorWrapper>
      <ContentLoader isLoading={isLoadingStartingTimes}>
        <>
       
            
        {durationSlots && durationSlots?.length > 0 ? (
          <>
           <DurationFilter
              availableStartingTimes={availableStartingTimes}
              selectedDay={selectedDay}
              bookingSlots={bookingSlots}
            />
            <AvailableStartingTimes
              availableStartingTimes={availableStartingTimes}
              bookingSlots={durationSlots}
            />
            <Typography className="no-slots-label">
              {getResourceDisclaimer(company!, selectedResource)}
            </Typography>
          </>
        ) : (
          <Typography className="no-slots-label">
            Sorry No Slots Available for the selected day please select another
            day
          </Typography>
        )}
        </>
      </ContentLoader>
    </SlotSelectorWrapper>

    // </SlotSelectorWrapper>
  );
};

export default TimeSelector;
