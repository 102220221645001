import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { up } from "styled-breakpoints";
export const SelectWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
  width: 180px;
  ${up("md")} {
    width: 240px;
  }
  .select-label {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-family: airbnb-cereal-app;
    font-weight: normal;
  }

  .select-menu {
    font-family: Martel Sans;
    overflow: hidden;
  }

  .select-menu-left-separator {
    ${up("md")} {
      border-left: 2px solid #d0d0d0;
      padding-left: 20px;
    }

    // margin-left: 20px;
  }
`;
