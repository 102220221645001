import React from "react";
import { Box, NativeSelect, Typography } from "@mui/material";
import { SelectWrapper } from "./style";

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  selectLabel: string;
  options: IOption[];
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  className?: string;
}

const CustomizedSelect: React.FC<IProps> = ({
  selectLabel,
  options,
  handleChange,
  value,
  className,
}) => {
  return (
    <SelectWrapper>
      <Box className={className}>
        <Typography className="select-label">{selectLabel}</Typography>
        <NativeSelect
          onChange={handleChange}
          value={value}
          className="select-menu"
          disableUnderline={true}
        >
          {options.map((element) => (
            <option
              key={element.value}
              value={element.value}
              className="select-menu-label"
            >
              {element.label}
            </option>
          ))}
        </NativeSelect>
      </Box>
    </SelectWrapper>
  );
};

export default CustomizedSelect;
