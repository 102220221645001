import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { FooterWrapper } from "./style";
import MasterCard from "../../../../src/assets/logos/MasterCard.svg";
import VisaCard from "../../../../src/assets/logos/VisaCard.svg";

import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

const Footer: React.FC = () => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const goToPath = (path: string) => {
    window.open(path, "_blank");
  };

  const formatContactDetails = () => {
    if (company?.companyPhoneNumber && company?.companyEmail) {
      return `For any questions please reach out to: ${company?.companyPhoneNumber} or
      ${company?.companyEmail}`;
    } else if (company?.companyPhoneNumber) {
      return `For any questions please reach out to: ${company?.companyPhoneNumber} `;
    } else if (company?.companyEmail) {
      return `For any questions please reach out to: ${company?.companyEmail} `;
    }
  };
  return (
    <FooterWrapper>
      <Box className="footer-company-logo">
        {company?.logo ? (
          <img
            src={company?.logo}
            alt="CompanyLogo"
            style={{ width: "75px", height: "75px" }}
          />
        ) : (
          <></>
        )}
      </Box>

      <Box className="footer-details">
        <Box className="links">
          <Typography
            className="links-item links-item-separator"
            onClick={() => goToPath(company?.footerPagesLinks.aboutUs!)}
          >
            About Us
          </Typography>
          <Typography
            className="links-item"
            onClick={() => goToPath(company?.footerPagesLinks.refund!)}
          >
            Refund Policy
          </Typography>
          <Typography
            className="links-item links-item-separator"
            onClick={() => goToPath(company?.footerPagesLinks.privacy!)}
          >
            Privacy Policy
          </Typography>
          <Typography
            className="links-item"
            onClick={() => goToPath(company?.footerPagesLinks.terms!)}
          >
            Terms & Conditions
          </Typography>
        </Box>

        <Box className="address">
          <Typography>
            {company?.companyAddress}{" "}
            <a href={company?.mapLink} className="map-link">
              (Map)
            </a>
          </Typography>
        </Box>

        <Box className="question">
          {(company?.companyPhoneNumber || company?.companyEmail) && (
            <Typography>{formatContactDetails()}</Typography>
          )}
        </Box>

        <Box className="payment-methods">
          <img
            src={MasterCard}
            alt="MasterCard"
            className="payment-methods-item"
          />

          <img src={VisaCard} alt="VisaCard" className="payment-methods-item" />
        </Box>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
