import { Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { AvailableStartingTimesDuration, AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";
import { TimeUnits, WEEKEND_DAYS } from "../../../Utils/constants";
import {
  convertMinutesToHours,
  convertMinutesToSessions,
  formatDurationTime,
} from "../../../Utils/format";
import { DurationChipWrapper } from "./style";
import dayjs from "dayjs";

interface IProps {
  duration:  AvailableStartingTimesDuration;
  index: number;
  selectedDay: string;
  bookingSlots: Map<string, AvailableStartingTimesSlot[]>;
}
const DurationChip: React.FC<IProps> = ({
  duration,
  index,
  selectedDay,
  bookingSlots
}) => {
  const { company, selectedResource, quantity , setSelectedDuration , selectedDuration  } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;
  const handleClick = () => {
    setSelectedDuration(duration);
  };



const getPrice = ()=> {
  // const isPeak = WEEKEND_DAYS.includes(dayjs(selectedDay, "DD/MM/YYYY").day())

  // const price = isPeak ? duration.peakPrice : duration.offPeakPrice;
  // return price * quantity * (duration.durationTime / (company?.serviceDurationMultiples || 15))
  const currentSlots = bookingSlots.get(duration.id)
  if(currentSlots && currentSlots.length > 0){
    return currentSlots[0].price * quantity
  }

  return null;
}

  return (
    <DurationChipWrapper
      onClick={handleClick}
      isSelected={selectedDuration!.id == duration.id}
    >
      <Typography className="duration">
        {formatDurationTime(duration.durationTime, company!, selectedResource)}
      </Typography>
      {getPrice() && <Typography className="price">{getPrice()} AED</Typography>}
    </DurationChipWrapper>
  );
};

export default DurationChip;
