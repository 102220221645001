import React from "react";
import {
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
  BookingSlot,
} from "../../../hooks/useAvailableStartingTimes";
import SlotChip from "./SlotChip";
import { SlotsListWrapper } from "./style";

interface IProps {
  slots: AvailableStartingTimesSlot[];
  availableStartingTimes: AvailableStartingTimesResource;
}
const SlotsList: React.FC<IProps> = ({
  slots,
  availableStartingTimes,
}) => {
  return (
    <SlotsListWrapper>
      {slots.map((slot) => (
        <SlotChip
          key={slot.time}
          slot={slot}
          availableStartingTimes={availableStartingTimes}
        />
      ))}
    </SlotsListWrapper>
  );
};

export default SlotsList;
