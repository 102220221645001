import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Calendar from "./pages/calendar";
import Cancellation from "./pages/cancellation";
import Checkout from "./pages/checkout";
import Confirmation from "./pages/confirmation";
import PackageCancellation from "./pages/package/packageCancellation";
import PackageConfirmation from "./pages/package/packageConfirmation";
import PackagePurchase from "./pages/package/packagePurchase";
import Status from "./pages/status";
import { AppWrapper } from "./Style";

const App: React.FC = () => {
  return (
    <AppWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/calendar" element={<Calendar title="Calendar" />} />
          <Route
            path="/checkout"
            element={<Checkout title="Booking Details" />}
          />
          <Route
            path="/confirmation"
            element={<Confirmation title="Confirmation" />}
          />
          <Route
            path="/Cancellation"
            element={<Cancellation title="Cancellation" />}
          />

          <Route path="/Status" element={<Status title="Status" />} />

          <Route
            path="/packageConfirmation"
            element={<PackageConfirmation title="Package Confirmation" />}
          />

          <Route path="/PackagePurchase" element={<PackagePurchase />} />

          <Route
            path="/PackageCancellation"
            element={<PackageCancellation title="PackageCancellation" />}
          />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  );
};

export default App;
