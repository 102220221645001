import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useCompany from "../../hooks/useCompany";
import dayjs from "dayjs";
import useAvailableStartingTimes from "../../hooks/useAvailableStartingTimes";
import ContextLoader from "../../components/ContentLoader";
import DaySelector from "../../components/Calendar/DaySelector";
import { CalendarWrapper } from "./style";
import TimeSelector from "../../components/Calendar/TimeSelector";
import Footer from "../../components/shared/footer";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import Header from "../../components/shared/header";
import ServiceSelector from "../../components/Calendar/ServiceSelector";
import QuantitySelector from "../../components/Calendar/QuantitySelector";
import useTitle from "../../hooks/useTitle";

interface IProps {
  title: string;
}

const Calendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const {
    setCompany,
    selectedLocation,
    selectedService,
    selectedResource,
    setAvailableStartingTimesResource,
    setResourcesMap,
    setServicesMap,
    setBranchesMap,
    shouldShowServiceDropdown,
    isAllResourcesOptionEnabled,
    setShouldShowServiceDropdown,
    setIsAllResourcesOptionEnabled,
    setSelectedDuration,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const companyId = searchParams.get("companyId");
  const branchId = searchParams.get("branchId");
  const serviceId = searchParams.get("serviceId");
  const resourceId = searchParams.get("resourceId");

  const {
    loading,
    company: fetchedCompany,
    resourcesMap,
    servicesMap,
    branchesMap,
  } = useCompany(companyId!);

  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
    new Date()
  );
  const [selectedDay, setSelectedDay] = useState<string>(
    dayjs(new Date()).format("DD/MM/YYYY")
  );
  

  useEffect(() => {
    setShouldShowServiceDropdown(!!serviceId);
    setIsAllResourcesOptionEnabled(resourceId === null);
  }, [
    serviceId,
    setShouldShowServiceDropdown,
    resourceId,
    setIsAllResourcesOptionEnabled,
  ]);

  useEffect(() => {
    setSelectedDay(dayjs(selectedPickerDate).format("DD/MM/YYYY"));
  }, [selectedPickerDate]);

  const selectedServiceId = shouldShowServiceDropdown
    ? selectedService
    : selectedResource &&
      resourcesMap &&
      resourcesMap.get(selectedResource)?.service.id;

  const {
    loading: isLoadingStartingTimes,
    availableStartingTimes,
    bookingSlots,
  } = useAvailableStartingTimes({
    companyId,
    branchId: selectedLocation,
    serviceId: selectedServiceId,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
  });

  useEffect(() => {
    if (isLoadingStartingTimes || !availableStartingTimes) return;
    setSelectedDuration(
    availableStartingTimes.duration[0]
    );
  }, [availableStartingTimes, isLoadingStartingTimes]);

  useEffect(() => {
    if (fetchedCompany && fetchedCompany.id) {
      setCompany(fetchedCompany);
      setResourcesMap(resourcesMap);
      setBranchesMap(branchesMap);
      setServicesMap(servicesMap);
      setAvailableStartingTimesResource(availableStartingTimes!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedCompany?.id, setCompany, availableStartingTimes]);

  const service = servicesMap?.get(selectedServiceId!);

  return (
    <ContextLoader isLoading={loading}>
      <>
        <CalendarWrapper>
          <Header headerLabel="Booking" showBackButton={true} />
          <ServiceSelector
            branchesMap={branchesMap}
            servicesMap={servicesMap}
            resourcesMap={resourcesMap}
            branchId={branchId}
            serviceId={serviceId}
            resourceId={resourceId}
          />

          <DaySelector
            selectedPickerDate={selectedPickerDate}
            setSelectedPickerDate={setSelectedPickerDate}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />

          {service?.maxQuantity && service.maxQuantity > 1 && (
            <QuantitySelector />
          )}

          <TimeSelector
            availableStartingTimes={availableStartingTimes!}
            isLoadingStartingTimes={isLoadingStartingTimes}
            bookingSlots={bookingSlots!}
            selectedDay={selectedDay}
          />
        </CalendarWrapper>
        <Footer />
      </>
    </ContextLoader>
  );
};

export default Calendar;
