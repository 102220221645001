import React, { useContext } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DatePickerWrapper } from "./style";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/dayjs";
import dayjs, { Dayjs } from "dayjs";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
interface IProps {
  selectedPickerDate: Date;
  setSelectedPickerDate: (date: any) => void;
}

const CustomizedDatePicker: React.FC<IProps> = ({
  selectedPickerDate,
  setSelectedPickerDate,
}) => {
  const {
    selectedService,
    servicesMap,
    shouldShowServiceDropdown,
    resourcesMap,
    selectedResource,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <DatePickerWrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          disablePast
          value={selectedPickerDate}
          onChange={(date) => {
            setSelectedPickerDate(date);
          }}
          autoOk={true}
          keyboardIcon={<CalendarMonthOutlinedIcon />}
          className="keyboard-date-picker"
          inputProps={{
            style: { visibility: "hidden", width: "0" },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          shouldDisableDate={(day: Dayjs | null) => {
            const selectedServiceId = shouldShowServiceDropdown
              ? selectedService
              : resourcesMap?.get(selectedResource)?.service?.id;

            const service = servicesMap?.get(selectedServiceId!);
            if (!service?.advancedBookingDays) return false;

            return (
              !!day &&
              day.isAfter(dayjs().add(service?.advancedBookingDays!, "day"))
            );
          }}
        />
      </MuiPickersUtilsProvider>
    </DatePickerWrapper>
  );
};

export default CustomizedDatePicker;
