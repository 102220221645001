import React from "react";
import { getCountries } from "react-phone-number-input";
import { alphabeticalSort } from "../../Utils/sort";
import { CountrySelectWrapper } from "./style";

const CountrySelect: React.FC<any> = ({ value, onChange, labels, ...rest }) => {
  const countries = getCountries().sort((a, b) =>
    alphabeticalSort(labels[a], labels[b])
  );
  return (
    <CountrySelectWrapper
      {...rest}
      value={value}
      onChange={(event) => onChange(event)}
    >
      <option value="">{labels["ZZ"]}</option>
      {countries.map((country) => (
        <option key={country} value={country}>
          {labels[country]}
        </option>
      ))}
    </CountrySelectWrapper>
  );
};

export default CountrySelect;
