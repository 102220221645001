import Axios from "axios";
import { useState } from "react";
import { BASE_URL } from "../Utils/constants";

interface CancelBookingInput {
  bookingId: string;
  onSuccessfulCancellation: () => void;
  onFailedCancellation: () => void;
}

const useCancelBooking = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelBooking = async ({
    bookingId,
    onSuccessfulCancellation,
    onFailedCancellation,
  }: CancelBookingInput) => {
    setIsLoading(true);
    try {
      const { data: response } = await Axios.post(`${BASE_URL}/cancelBooking`, {
        bookingId,
      });
      if (response.error === 0) {
        setIsLoading(false);
        onSuccessfulCancellation();
      } else {
        setIsLoading(false);
        onFailedCancellation();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      onFailedCancellation();
    }
  };

  return { isLoading, cancelBooking };
};

export default useCancelBooking;
