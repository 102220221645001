import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CancellationHeader from "../../components/cancellation/CancelationHeader";
import CancelBookingButton from "../../components/cancellation/CancelBookingButton";
import ConfirmationBookingDetails from "../../components/shared/bookingDetails/ConfirmationBookingDetails";
import useTitle from "../../hooks/useTitle";

import { extractBookingDetails } from "../../Utils/extract";
import { CancellationWrapper } from "./style";

interface IProps {
  title: string;
}
const Cancellation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();

  const { companyName } = extractBookingDetails(searchParams);

  return (
    <CancellationWrapper>
      <CancellationHeader />
      <ConfirmationBookingDetails searchParams={searchParams} />
      <CancelBookingButton searchParams={searchParams} />
    </CancellationWrapper>
  );
};

export default Cancellation;
