import React from "react";
import { CircularProgress } from "@mui/material";
import { LoaderWrapper } from "./style";

interface IProps {
  isLoading: boolean;
  children: JSX.Element;
}

const ContextLoader: React.FC<IProps> = ({ isLoading, children }) => {
  if (isLoading)
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  return children;
};

export default ContextLoader;
